import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ButtonRow, FormStepper } from "@omnigenbiodata/react";
import MainLayout from "../../../../layouts/Main";
import { useAppSelector } from "../../../../store/index";
import { barcodesSelector } from "../../../../store/receipt/selectors";
import { ReceiptMatrix } from "../../../../core/utils/ReceiptMatrix";

function TotalsConfirmScene() {
  const barcodes: string[] = useAppSelector(barcodesSelector);
  const receiptMatrix = new ReceiptMatrix(barcodes);
  const batches = receiptMatrix.batches;

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Receipt of Samples
      </Typography>
      <FormStepper steps={["Receipt", "Confirm", "Complete"]} activeStep={2} />

      <Box mb={4}>
        <Box pt={10} pb={10}>
          <Typography paragraph component="h2" variant="h5" align="center">
            Confirm Samples
          </Typography>

          <Typography paragraph component="h3" variant="h6" align="left">
            Total Valid Sample Sets <strong>{batches.length}</strong>
          </Typography>
        </Box>
      </Box>
      <ButtonRow />
    </MainLayout>
  );
}

export default TotalsConfirmScene;
