import validatejs from "validate.js";

export class PrimaryBarcode {
  isPrimary: boolean = true;
  value: string;
  seedBarcode?: string;
  isValid = false;
  messages?: string[];
  charLength: number = 7;

  constructor(barcode: string, seedBarcode?: string) {
    this.value = barcode;
    this.seedBarcode = seedBarcode;
    this.validate(barcode);
  }

  validate = (barcode: string) => {
    let constraints: any = {
      barcode: {
        presence: true,
        format: function () {
          return {
            pattern: /^([0-9]{7})$/,
            message: "Invalid primary barcode",
          };
        },
        length: function (value: string) {
          if (value) {
            return {
              is: 7,
              message: `Not 7 digits`,
            };
          }
          return false;
        },
      },
    };

    if (barcode) {
      if (this.seedBarcode && this.seedBarcode !== this.value) {
        this.isValid = false;
      } else {
        this.messages = validatejs({ barcode }, constraints, {
          fullMessages: false,
        });
        this.isValid = !this.messages;
      }
    }

    return this.isValid;
  };
}
export default PrimaryBarcode;
