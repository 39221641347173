import _ from "lodash";
import Sample from "./sample.class";
import AliquotRow from "./row.class";

export class SampleMatrix {
  barcodes: string[] = [];
  aliquots: string[] = [];
  samples: Sample[] = [];
  columns: number = 2;
  aliquotRows: AliquotRow[] = [];
  sampleType: string | null;
  isValid: boolean = false;
  canScan: boolean = false;

  constructor(
    sampleType: string | null,
    barcodes: string[],
    aliquots: string[],
    exceptions?: string[]
  ) {
    this.sampleType = sampleType;
    this.barcodes = barcodes;

    this.samples = this.barcodes.map((barcode) => {
      return new Sample(barcode);
    });

    this.aliquots = aliquots;

    this.aliquotRows = _.chunk(this.aliquots, 2).map(
      (aliquotRow: string[], index) =>
        new AliquotRow(
          aliquotRow,
          this.samples[index],
          this.sampleType,
          this.columns,
          exceptions
        )
    );

    this.isValid =
      this.aliquotRows.filter((row) => !row.isValid).length > 0 ? false : true;

    const prevRow = [...this.aliquotRows]
      .filter((row) => row.aliquots.length === this.columns)
      .pop();

    this.canScan =
      !prevRow || (prevRow.aliquots.length === this.columns && prevRow.isValid);
  }
}
