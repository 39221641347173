import { createSlice } from "@reduxjs/toolkit";
import { SampleState } from "./types";
import { StoreStatus } from "../../core/types/common.types";

// Initial State
const initialState: SampleState = {
  errors: null,
  barcodes: null,
  aliquots: null,
  aliquots2: null,
  exceptions: null,
  status: StoreStatus.IDLE,
  values: null,
  sampleType: null,
};
// Slice
export const sampleSlice = createSlice({
  name: "samples",
  initialState,
  reducers: {
    setSampleType: (state, action) => ({
      ...state,
      sampleType: action.payload,
      barcodes: null,
      aliquots: null,
      aliquots2: null,
    }),
    addBarcode: (state, action) => ({
      ...state,
      barcodes: state.barcodes
        ? [...state.barcodes, action.payload]
        : [action.payload],
      aliquots: null,
      aliquots2: null,
    }),
    removeBarcode: (state, action) => ({
      ...state,
      barcodes: state.barcodes
        ? [...state.barcodes.filter((bcode) => action.payload !== bcode)]
        : [],
    }),
    addAliquots: (state, action) => ({
      ...state,
      aliquots: state.aliquots
        ? [...state.aliquots, action.payload]
        : [action.payload],
    }),
    removeAliquots: (state, action) => ({
      ...state,
      aliquots: state.aliquots
        ? [...state.aliquots.filter((alq) => !action.payload.includes(alq))]
        : [],
    }),
    addAliquots2: (state, action) => ({
      ...state,
      aliquots2: state.aliquots2
        ? [...state.aliquots2, action.payload]
        : [action.payload],
    }),
    addExptions: (state, action) => ({
      ...state,
      exceptions: state.exceptions
        ? [...state.exceptions, ...action.payload]
        : [...action.payload],
    }),
    removeAliquots2: (state, action) => ({
      ...state,
      aliquots2: state.aliquots2
        ? [...state.aliquots2.filter((alq) => !action.payload.includes(alq))]
        : [],
    }),
    sampleForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
    }),
  },
  extraReducers: {},
});

// Action creators
export const {
  addAliquots,
  addAliquots2,
  addExptions,
  addBarcode,
  sampleForward,
  setSampleType,
  removeAliquots,
  removeAliquots2,
  removeBarcode,
} = sampleSlice.actions;

export default sampleSlice.reducer;
