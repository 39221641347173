import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Box, Paper, Typography } from "@material-ui/core";
import { ButtonRow, FormInput, FormStepper } from "@omnigenbiodata/react";
import { RiAlertLine, RiArrowRightLine, RiCheckLine } from "react-icons/ri";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/index";
import {
  aliquots2Selector,
  barcodesSelector,
  sampleTypeSelector,
  exceptionsSelector,
} from "../../../../store/sampling/selectors";
import {
  addAliquots2,
  removeAliquots2,
  sampleForward,
  addExptions,
} from "../../../../store/sampling";
import aliquotUtils from "../../../../core/utils/aliquots.util";
import { SampleMatrix } from "../../../../core/utils/Samples";
import IconButton from "@material-ui/core/IconButton";

export type typeOptions = {
  [key: string]: string;
};

const SAMPLE_TYPES: typeOptions = {
  WHOLE: "Whole Blood",
  PLASMA: "Plasma Blood",
  SERUM: "Serum Blood",
};

function Aliquots3() {
  const history = useHistory();
  const barcodes = useAppSelector(barcodesSelector);
  const aliquots = useAppSelector(aliquots2Selector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const exceptions = useAppSelector(exceptionsSelector);

  const dispatch = useAppDispatch();
  const { requiredAliquots } = aliquotUtils.checkvalidAliquots(
    barcodes,
    aliquots
  );

  const samples = new SampleMatrix(sampleType, barcodes, aliquots, exceptions);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      barcodes: barcodes,
      aliquots: aliquots,
    },
    initialTouched: {
      barcodes: false,
      aliquots: true,
    },
    onSubmit: (values: any) => {
      dispatch(sampleForward(values));
      history.push(ROUTES.samplingAliquotsConfirm);
    },
  });

  useEffect(() => {
    const scanFunc: any = (event: any) => {
      const code: string = event.detail.scanCode;

      if (code) {
        if (
          !aliquots?.includes(code as never) &&
          aliquots.length < requiredAliquots &&
          aliquotUtils.isValidPrimaryAliquotSimple(code, sampleType) &&
          samples.canScan
        ) {
          dispatch(addAliquots2(code));
        }
      }
      return null;
    };
    document.addEventListener("scan", scanFunc, false);
    return () => {
      document.removeEventListener("scan", scanFunc);
    };
  }, [aliquots, dispatch, requiredAliquots, sampleType, samples.canScan]);

  if (!sampleType) {
    return <Redirect to={ROUTES.sampling} />;
  }

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={2}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Paper elevation={3} variant="elevation">
            <Box pt={10} pb={10} pl={10} pr={10}>
              <Typography paragraph component="h2" variant="h5" align="center">
                Confirm Positions
              </Typography>
              <Typography
                paragraph
                component="p"
                variant="body1"
                align="center"
              >
                Please re-scan the labels of the filled{" "}
                <strong
                  style={{
                    color: sampleType === "SERUM" ? "red" : "purple",
                  }}
                >
                  {SAMPLE_TYPES[sampleType]}
                </strong>{" "}
                aliquots based on their position in the cryovial racks starting
                from position 1
              </Typography>

              {/* <pre>{JSON.stringify(samples, null, 4)}</pre> */}

              {barcodes.length > 0 && (
                <table width={"100%"} style={{ margin: "0 auto" }}>
                  <thead>
                    <tr>
                      <th align="center" style={{ width: "30" }}></th>
                      <th align="center" style={{ width: "30" }}>
                        Pos
                      </th>
                      <th align="center">Barcode </th>
                      <th align="left">Aliquot 1</th>
                      <th align="left">Aliquot 2</th>
                      <th align="center" style={{ width: 130 }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {barcodes.map((bcode, index) => (
                      <tr
                        key={bcode}
                        style={{
                          background: samples.aliquotRows[index]
                            ? samples.aliquotRows[index]?.isValid
                              ? "white"
                              : "#EDCCCC"
                            : "white",
                        }}
                      >
                        <td align="center">
                          {samples.aliquotRows[index] ? (
                            samples.aliquotRows[index]?.isValid ? (
                              <RiCheckLine size={30} color="green" />
                            ) : (
                              <RiAlertLine size={30} color="red" />
                            )
                          ) : samples.aliquotRows[index]?.aliquots?.length ===
                            2 ? (
                            <RiArrowRightLine size={30} color="grey" />
                          ) : index === 0 ? (
                            <RiArrowRightLine size={30} color="grey" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td align="center">{index + 1}</td>
                        <td align="center">
                          <FormInput
                            label="Primary ID"
                            name={`barcodes[${index}]`}
                            error={
                              formik.errors?.barcodes
                                ? formik.errors?.barcodes[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.barcodes[index]}
                            disabled
                          />
                          <Typography
                            component="p"
                            variant="body2"
                            align="left"
                          >
                            Confirmed
                          </Typography>
                        </td>
                        <td>
                          <FormInput
                            label="Aliquot 1 ID"
                            name={`aliquots[${index}]`}
                            error={
                              samples.aliquotRows[index]?.aliquots[0]?.isValid
                                ? undefined
                                : ""
                            }
                            touched={
                              samples.aliquotRows[index]?.aliquots[0]?.value
                                ? true
                                : false
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[0]?.value ||
                              ""
                            }
                            disabled
                          />
                          {!samples.aliquotRows[index]?.aliquots[0] ? (
                            <Typography
                              component="p"
                              variant="body2"
                              align="left"
                            >
                              Pending
                            </Typography>
                          ) : samples.aliquotRows[index]?.aliquots[0]
                              ?.isValid ? (
                            samples.aliquotRows[index]?.aliquots[0]
                              ?.isException ? (
                              <Typography
                                component="p"
                                variant="body2"
                                align="left"
                                color="error"
                              >
                                Exception
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                variant="body2"
                                align="left"
                                color="primary"
                              >
                                Valid
                              </Typography>
                            )
                          ) : (
                            <Typography
                              component="p"
                              variant="body2"
                              align="left"
                              color="error"
                            >
                              Invalid
                            </Typography>
                          )}
                        </td>
                        <td>
                          <FormInput
                            label="Aliquot 2 ID"
                            name={`aliquots[${index}]`}
                            error={
                              samples.aliquotRows[index]?.aliquots[1]?.isValid
                                ? undefined
                                : ""
                            }
                            touched={
                              samples.aliquotRows[index]?.aliquots[1]?.value
                                ? true
                                : false
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[1]?.value ||
                              ""
                            }
                            disabled
                          />
                          {!samples.aliquotRows[index]?.aliquots[1] ? (
                            <Typography
                              component="p"
                              variant="body2"
                              align="left"
                              color="textPrimary"
                            >
                              Pending
                            </Typography>
                          ) : samples.aliquotRows[index]?.aliquots[1]
                              ?.isValid ? (
                            samples.aliquotRows[index]?.aliquots[1]
                              ?.isException ? (
                              <Typography
                                component="p"
                                variant="body2"
                                align="left"
                                color="error"
                              >
                                Exception
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                variant="body2"
                                align="left"
                                color="primary"
                              >
                                Valid
                              </Typography>
                            )
                          ) : (
                            <Typography
                              component="p"
                              variant="body2"
                              align="left"
                              color="error"
                            >
                              Invalid
                            </Typography>
                          )}
                        </td>
                        <td>
                          {samples.aliquotRows.length - 1 === index &&
                            samples.aliquotRows[index] &&
                            !samples.aliquotRows[index]?.isValid && (
                              <>
                                <IconButton
                                  aria-label="delete"
                                  size="medium"
                                  onClick={() => {
                                    dispatch(
                                      removeAliquots2(
                                        samples.aliquotRows[index].aliquots.map(
                                          (a) => a.value
                                        )
                                      )
                                    );
                                  }}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                  aria-label="lock"
                                  size="medium"
                                  onClick={() => {
                                    dispatch(
                                      addExptions(
                                        samples.aliquotRows[index].aliquots
                                          .filter((a) => !a.isValid)
                                          .map((a) => a.value)
                                      )
                                    );
                                    console.log(
                                      samples.aliquotRows[index].aliquots
                                        .filter((a) => !a.isValid)
                                        .map((a) => a.value)
                                    );
                                  }}
                                >
                                  <CheckIcon fontSize="inherit" />
                                </IconButton>
                              </>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          </Paper>
        </Box>

        <ButtonRow
          showForward={
            samples.aliquotRows.length > 0 &&
            samples.isValid &&
            samples.aliquotRows.length === barcodes.length
          }
          forwardLabel="Continue"
        />
      </form>
    </MainLayout>
  );
}

export default Aliquots3;
