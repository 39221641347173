import React from "react";
import { useFormik } from "formik";
import { Box, Paper, Typography } from "@material-ui/core";
import { ButtonRow, FormInput, FormStepper } from "@omnigenbiodata/react";
import { RiCheckLine } from "react-icons/ri";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../store/index";
import {
  aliquotsSelector,
  barcodesSelector,
  sampleTypeSelector,
} from "../../../../store/sampling/selectors";
import { SampleMatrix } from "../../../../core/utils/Samples";

function Aliquots2() {
  const history = useHistory();
  const barcodes = useAppSelector(barcodesSelector);
  const aliquots = useAppSelector(aliquotsSelector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = new SampleMatrix(sampleType, barcodes, aliquots);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      barcodes: barcodes,
      aliquots: aliquots,
    },
    onSubmit: () => {
      history.push(ROUTES.samplingAliquots3);
    },
  });

  if (!sampleType) {
    return <Redirect to={ROUTES.sampling} />;
  }

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={2}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Paper elevation={3} variant="elevation">
            <Box pt={10} pb={10} pl={10} pr={10}>
              <Typography paragraph component="h2" variant="h5" align="center">
                Start Aliquotting
              </Typography>
              <Typography
                paragraph
                component="p"
                variant="body1"
                align="center"
              >
                YOU MAY NOW PROCEED WITH ALIQUOTING - YOU MUST KEEP ALIQUOTS IN
                THE ORDER SHOWN BELOW
              </Typography>

              {samples.aliquotRows.length > 0 && barcodes.length > 0 && (
                <table width={"100%"} style={{ margin: "0 auto" }}>
                  <thead>
                    <tr>
                      <th align="center" style={{ width: "30" }}></th>
                      <th align="center" style={{ width: "30" }}>
                        Pos
                      </th>
                      <th align="center">Barcode </th>
                      <th align="left">Aliquot 1</th>
                      <th align="left">Aliquot 2</th>
                    </tr>
                  </thead>

                  <tbody>
                    {barcodes.map((bcode, index) => (
                      <tr
                        key={bcode}
                        style={{
                          background: "#D8EDCC",
                        }}
                      >
                        <td align="center">
                          <RiCheckLine size={30} color="green" />
                        </td>
                        <td align="center">{index + 1}</td>
                        <td align="center">
                          <FormInput
                            label="Primary ID"
                            name={`barcodes[${index}]`}
                            error={
                              formik.errors?.barcodes
                                ? formik.errors?.barcodes[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.barcodes[index]}
                            disabled
                          />
                        </td>
                        <td>
                          <FormInput
                            label="Aliquot 1 ID"
                            name={`aliquots[${index}]`}
                            error={
                              formik.errors?.aliquots
                                ? formik.errors?.aliquots[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[0]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                        <td>
                          <FormInput
                            label="Aliquot 2 ID"
                            name={`aliquots[${index}]`}
                            error={
                              formik.errors?.aliquots
                                ? formik.errors?.aliquots[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[1]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          </Paper>
        </Box>

        <ButtonRow
          showForward={samples.aliquotRows.length > 0}
          forwardLabel="Aliquotting Complete"
        />
      </form>
    </MainLayout>
  );
}

export default Aliquots2;
