import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.sampling.errors && state.sampling.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.sampling.status === StoreStatus.BUSY;

export const barcodesSelector = (state: RootState): string[] =>
  state.sampling.barcodes || [];

export const aliquotsSelector = (state: RootState): string[] =>
  state.sampling.aliquots || [];

export const aliquots2Selector = (state: RootState): string[] =>
  state.sampling.aliquots2 || [];

export const exceptionsSelector = (state: RootState): string[] =>
  state.sampling.exceptions || [];

export const sampleTypeSelector = (state: RootState): string | null =>
  state.sampling.sampleType;
