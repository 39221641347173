import Aliquot from "./aliquotbarcode.class";
import Sample from "./sample.class";

export class AliquotRow {
  aliquots: Aliquot[];
  barcode: Sample;
  indez: number = 0;
  isValid: boolean = false;
  isComplete: boolean = false;
  isException: boolean = false;
  columns: number = 1;
  exceptions?: string[];

  constructor(
    aliquots: string[],
    barcode: Sample,
    sampleType: string | null,
    columns: number,
    exceptions?: string[]
  ) {
    this.aliquots = aliquots.map((aliquot, index) => {
      return new Aliquot(
        barcode.value,
        aliquot,
        sampleType,
        index,
        columns,
        exceptions
      );
    });
    this.barcode = barcode;
    this.columns = columns;
    this.exceptions = exceptions;
    this.validate();
    this.checkExcpetions();
    this.checkCompletion();
  }

  validate = () => {
    this.isValid =
      this.aliquots.filter((aliquot) => !aliquot.isValid).length === 0
        ? true
        : false;
    return this.isValid;
  };

  checkExcpetions = () => {
    this.isException =
      this.aliquots.filter((aliquot) => !aliquot.isException).length === 0
        ? true
        : false;
    return this.isException;
  };

  checkCompletion = () => {
    this.isComplete =
      this.aliquots.filter((aliquot) =>
        this.barcode ? aliquot.value.includes(this.barcode.value) : false
      ).length === this.columns;
    return this.isComplete;
  };
}
export default AliquotRow;
