import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Box, Typography, Paper } from "@material-ui/core";
import {
  FormInput,
  ButtonRow,
  FormSelect,
  FormStepper,
  SelectOption,
} from "@omnigenbiodata/react";
import { RiBarcodeLine } from "react-icons/ri";
import _ from "lodash";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/index";
import {
  barcodesSelector,
  hasErrorSelector,
  sampleTypeSelector,
} from "../../../../store/sampling/selectors";
import {
  addBarcode,
  sampleForward,
  removeBarcode,
} from "../../../../store/sampling";
import aliquotUtils from "../../../../core/utils/aliquots.util";
import {
  PRIMARY_STATUSES,
  statusOptions,
} from "../../../../core/constants/values.constants";

export type typeOptions = {
  [key: string]: string;
};

const SAMPLE_TYPES: typeOptions = {
  WHOLE: "Whole Blood",
  PLASMA: "Plasma Blood",
  SERUM: "Serum Blood",
};

function PrimaryScene() {
  const history = useHistory();
  const barcodes = useAppSelector(barcodesSelector);
  const error = useAppSelector(hasErrorSelector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const scanFunc: any = (event: any) => {
      const code: string = event.detail.scanCode;

      if (code) {
        if (
          !barcodes?.includes(code as never) &&
          barcodes.length < 10 &&
          aliquotUtils.isValidPrimaryBarcode(code)
        ) {
          dispatch(addBarcode(code));
        }
      }
      return null;
    };
    document.addEventListener("scan", scanFunc, false);
    return () => {
      document.removeEventListener("scan", scanFunc);
    };
  }, [barcodes, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      volume: _.times(barcodes.length, () => "6"),
      condition: _.times(barcodes.length, () => "SAT"),
      barcode: barcodes,
    },
    onSubmit: (values: any) => {
      dispatch(sampleForward(values));
      history.push(ROUTES.samplingAliquots);
    },
  });

  if (!sampleType) {
    return <Redirect to={ROUTES.sampling} />;
  }

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={1}
      />

      {error && <p>That went wrong</p>}

      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Paper elevation={3} variant="elevation">
            <Box pt={10} pb={10} pl={10} pr={10}>
              <Typography paragraph component="h2" variant="h5" align="center">
                Primary Samples
              </Typography>
              <input type="text" style={{ width: 1, opacity: 0 }} autoFocus />

              <Typography
                paragraph
                component="p"
                variant="body1"
                align="center"
              >
                Please scan the barcode labels of the primary sample tubes for{" "}
                <strong
                  style={{
                    color: sampleType === "SERUM" ? "red" : "purple",
                  }}
                >
                  {SAMPLE_TYPES[sampleType]}
                </strong>{" "}
                you are aliquotting in the order they are arranged in the tube
                rack (maximum 10 samples) :
              </Typography>

              {formik.values.barcode.length > 0 && (
                <Typography align="center">
                  Please update the primary sample information as required
                </Typography>
              )}
              {formik.values.barcode.length === 0 && (
                <>
                  <div style={{ fontSize: 100, textAlign: "center" }}>
                    <RiBarcodeLine color="#cccccc" />
                  </div>

                  <Typography align="center">
                    Scan your first primary sample tube barcode
                  </Typography>
                </>
              )}
              {formik.values.barcode.length > 0 && (
                <Box mb={5}>
                  <table width={"100%"} style={{ margin: "0 auto" }}>
                    <thead>
                      <tr>
                        <th align="center">Pos</th>
                        <th align="left">Barcode ID</th>
                        <th align="left">Volume (mm)</th>
                        <th align="left">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {formik.values.barcode.map(
                        (barcode: string, index: number) => (
                          <tr key={barcode}>
                            <td
                              align="center"
                              style={{
                                borderLeft:
                                  sampleType === "SERUM"
                                    ? "5px solid red"
                                    : "4PX solid purple",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td>
                              <FormInput
                                label="Barcode"
                                name={`barcode[${index}]`}
                                error={
                                  formik.errors?.volume
                                    ? formik.errors?.volume[index]
                                    : undefined
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.barcode[index]}
                                disabled
                              />
                            </td>
                            <td>
                              <FormInput
                                label="Volume"
                                name={`volume[${index}]`}
                                error={
                                  formik.errors?.volume
                                    ? formik.errors?.volume[index]
                                    : undefined
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                touched={
                                  formik.values.volume[index] ? true : false
                                }
                                value={formik.values.volume[index]}
                              />
                            </td>
                            <td>
                              <FormSelect
                                label="Condition"
                                name="condition[]"
                                onChange={() => undefined}
                                onBlur={() => undefined}
                                placeholder="Condition"
                                options={
                                  Object.keys(PRIMARY_STATUSES).reduce(
                                    (
                                      previousValue: any[],
                                      key: keyof statusOptions
                                    ) => {
                                      return [
                                        ...previousValue,
                                        {
                                          value: key,
                                          label: PRIMARY_STATUSES[key],
                                        },
                                      ];
                                    },
                                    []
                                  ) as SelectOption[]
                                }
                                value={formik.values.condition[index]}
                              />
                            </td>
                            <td>
                              {index === barcodes.length - 1 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(removeBarcode(barcode));
                                  }}
                                >
                                  X
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
        <ButtonRow showForward={formik.values.barcode.length > 0} />
      </form>
    </MainLayout>
  );
}

export default PrimaryScene;
