import _ from "lodash";
import Aliquot from "./aliquotbarcode.class";
import PrimaryBarcode from "./primarybarcode.class";

export class SampleBatch {
  barcodesRaw: string[] = [];
  barcodes: (PrimaryBarcode | Aliquot)[] = [];
  isValid = false;
  messages?: string[];
  uniqueAliquots?: Aliquot[];
  aliquots?: Aliquot[];
  requiresPrimary: boolean = true;

  constructor(barcodes: string[]) {
    this.setBarcodes(barcodes);
  }

  setBarcodes = (barcodes: string[]) => {
    this.barcodesRaw = barcodes;
    this.barcodes = barcodes.map((code, index) => {
      if (code) {
        if (index === 0) {
          return new PrimaryBarcode(code);
        } else if (index <= 2) {
          return new PrimaryBarcode(code, barcodes[0]);
        } else {
          return new Aliquot(barcodes[0], code);
        }
      } else {
        return new PrimaryBarcode(code);
      }
    });

    this.validate();
  };

  validate = () => {
    if (this.barcodes && this.barcodes.length > 0) {
      this.requiresPrimary =
        this.barcodes.filter((barcode) => barcode.isPrimary).length < 3;

      this.aliquots = this.barcodes.filter(
        (barcode) => !barcode.isPrimary
      ) as Aliquot[];

      this.uniqueAliquots = _.uniqBy(
        this.barcodes.filter((barcode) => !barcode.isPrimary) as Aliquot[],
        "value"
      );

      console.log(this.uniqueAliquots);

      this.isValid =
        this.barcodes.filter((barcode) => !barcode.isValid).length === 0 &&
        this.barcodes.length > 0 &&
        this.aliquots.length === this.uniqueAliquots.length;
    } else {
      this.isValid = false;
      this.requiresPrimary = true;
    }
  };
}
export default SampleBatch;
