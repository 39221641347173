import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../core/constants/routes.constants";
import TotalsScene from "./scenes/Totals";
import TotalsConfirmScene from "./scenes/TotalsConfirm";

function SamplingScene() {
  return (
    <Switch>
      <Route path={ROUTES.receiptConfirm} component={TotalsConfirmScene} />
      <Route path={ROUTES.receipt} component={TotalsScene} />
    </Switch>
  );
}

export default SamplingScene;
