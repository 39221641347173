export const ROUTES = {
  root: "/",
  sampling: "/sampling",
  samplingPrimary: "/sampling/primary",
  samplingAliquots: "/sampling/aliquots",
  samplingAliquots2: "/sampling/aliquots2",
  samplingAliquots3: "/sampling/aliquots3",
  samplingAliquotsConfirm: "/sampling/aliquotsConfirm",
  samplingAliquotsFinalise: "/sampling/aliquotsFinalise",
  receipt: "/receipt",
  receiptConfirm: "/receipt/confirm",
  storage: "/storage",
};
