import React from "react";
import MainLayout from "../../layouts/Main";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { RiDropLine, RiInboxArchiveLine, RiStore3Line } from "react-icons/ri";

import { ROUTES } from "../../core/constants/routes.constants";

function HomeScene() {
  return (
    <MainLayout>
      <Box mb={2}>
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          component={Link}
          to={ROUTES.receipt}
        >
          <RiInboxArchiveLine
            fontSize="inherit"
            style={{ marginRight: 10, fontSize: "1.4em" }}
          />
          Sample Receipt
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          component={Link}
          to={ROUTES.sampling}
        >
          <RiDropLine
            fontSize="inherit"
            style={{ marginRight: 10, fontSize: "1.4em" }}
          />
          Sample Processing
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          fullWidth
          color="default"
          size="large"
          variant="outlined"
          component={Link}
          to={ROUTES.storage}
        >
          <RiStore3Line
            fontSize="inherit"
            style={{ marginRight: 10, fontSize: "1.4em" }}
          />
          Sample Storage
        </Button>
      </Box>
    </MainLayout>
  );
}

export default HomeScene;
