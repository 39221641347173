import React from "react";
import MainLayout from "../../../../layouts/Main";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Typography } from "@material-ui/core";

function CompleteScene() {
  const history = useHistory();
  const handleClick = () => {
    history.push(ROUTES.root);
  };

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <Typography paragraph component="h2" variant="h5" align="center">
        Aliquotting Complete
      </Typography>
      <Typography paragraph component="p" variant="body1" align="center">
        Your aliquots have been stored
      </Typography>
      <Box mb={2}>
        <Button
          fullWidth
          color="default"
          size="large"
          variant="outlined"
          onClick={() => {
            handleClick();
          }}
        >
          Back to homepage
        </Button>
      </Box>
    </MainLayout>
  );
}

export default CompleteScene;
