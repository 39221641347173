import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Sampling from "./Sampling";
import Receipt from "./Receipt";
import { ROUTES } from "../core/constants/routes.constants";

function Scenes() {
  return (
    <Switch>
      <Route path={ROUTES.sampling} component={Sampling} />
      <Route path={ROUTES.receipt} component={Receipt} />
      <Route path={ROUTES.root} component={Home} />
    </Switch>
  );
}

export default Scenes;
