import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Box, Paper, Typography } from "@material-ui/core";
import { ButtonRow, FormInput, FormStepper } from "@omnigenbiodata/react";
import DeleteIcon from "@material-ui/icons/Delete";
import { RiCheckLine, RiAlertLine, RiArrowRightLine } from "react-icons/ri";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/index";
import {
  aliquotsSelector,
  barcodesSelector,
  sampleTypeSelector,
} from "../../../../store/sampling/selectors";
import {
  addAliquots,
  sampleForward,
  removeAliquots,
} from "../../../../store/sampling";
import aliquotUtils from "../../../../core/utils/aliquots.util";
import { SampleMatrix } from "../../../../core/utils/Samples";
import IconButton from "@material-ui/core/IconButton";

function Aliquots() {
  const history = useHistory();
  const barcodes = useAppSelector(barcodesSelector);
  const aliquots = useAppSelector(aliquotsSelector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const dispatch = useAppDispatch();
  const { requiredAliquots } = aliquotUtils.checkvalidAliquots(
    barcodes,
    aliquots
  );

  const samples = new SampleMatrix(sampleType, barcodes, aliquots);

  useEffect(() => {
    const scanFunc: any = (event: any) => {
      const code: string = event.detail.scanCode;

      if (code) {
        if (
          !aliquots?.includes(code as never) &&
          aliquots.length < requiredAliquots &&
          aliquotUtils.isValidPrimaryAliquotSimple(code, sampleType) &&
          samples.canScan
        ) {
          dispatch(addAliquots(code));
        }
      }
      return null;
    };
    document.addEventListener("scan", scanFunc, false);
    return () => {
      document.removeEventListener("scan", scanFunc);
    };
  }, [aliquots, dispatch, requiredAliquots, sampleType, samples.canScan]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aliquot1: aliquots,
    },
    onSubmit: (values: any) => {
      dispatch(sampleForward(values));
      history.push(ROUTES.samplingAliquots2);
    },
  });

  if (!sampleType) {
    return <Redirect to={ROUTES.sampling} />;
  }

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>

      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={2}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Paper elevation={3} variant="elevation">
            <Box pt={10} pb={10} pl={10} pr={10}>
              <Typography paragraph component="h2" variant="h5" align="center">
                Aliquot Initial Positions
              </Typography>
              <Typography
                paragraph
                component="p"
                variant="body1"
                align="center"
              >
                Please scan the labels on the EMPTY cryovials linked to that
                participant
              </Typography>

              {/* <pre>{JSON.stringify(samples, null, 4)}</pre> */}

              {barcodes.length > 0 && (
                <table width={"100%"} style={{ margin: "0 auto" }}>
                  <thead>
                    <tr>
                      <th align="center" style={{ width: "30" }}></th>
                      <th align="center" style={{ width: "30" }}>
                        Pos
                      </th>
                      <th align="center">Barcode </th>
                      <th align="left">Aliquot 1 ID</th>
                      <th align="left">Aliquot 2 ID</th>
                      <th align="center" style={{ width: 50 }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {barcodes.map((bcode, index) => (
                      <tr
                        key={bcode}
                        style={{
                          background: samples.aliquotRows[index]
                            ? samples.aliquotRows[index]?.isValid
                              ? "white"
                              : "#EDCCCC"
                            : "white",
                          opacity: samples.aliquotRows[index] ? 1 : 0.5,
                        }}
                      >
                        <td align="center">
                          {samples.aliquotRows[index] ? (
                            samples.aliquotRows[index]?.isValid ? (
                              <RiCheckLine size={30} color="green" />
                            ) : (
                              <RiAlertLine size={30} color="red" />
                            )
                          ) : samples.aliquotRows[index]?.aliquots?.length ===
                            2 ? (
                            <RiArrowRightLine size={30} color="grey" />
                          ) : index === 0 ? (
                            <RiArrowRightLine size={30} color="grey" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td align="center">{index + 1}</td>
                        <td align="center">({bcode})</td>
                        <td>
                          <FormInput
                            label="Aliquot1"
                            name={`aliquot1[${index}]`}
                            error={
                              formik.errors?.aliquot1
                                ? formik.errors?.aliquot1[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[0]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                        <td>
                          <FormInput
                            label="Aliquot2"
                            name={`aliquot1[${index}]`}
                            error={
                              formik.errors?.aliquot1
                                ? formik.errors?.aliquot1[index]
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[1]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                        <td>
                          {samples.aliquotRows.length - 1 === index &&
                            samples.aliquotRows[index] &&
                            !samples.aliquotRows[index]?.isValid && (
                              <>
                                <IconButton
                                  aria-label="delete"
                                  size="medium"
                                  onClick={() => {
                                    dispatch(
                                      removeAliquots(
                                        samples.aliquotRows[index].aliquots.map(
                                          (a) => a.value
                                        )
                                      )
                                    );
                                  }}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          </Paper>
        </Box>
        <ButtonRow
          showForward={
            samples.aliquotRows.length > 0 &&
            samples.isValid &&
            samples.aliquotRows.length === barcodes.length
          }
        />
      </form>
    </MainLayout>
  );
}

export default Aliquots;
