export type statusOptions = {
  [key: string]: string;
};

export const PRIMARY_STATUSES: statusOptions = {
  SAT: "Satisfactory",
  EQF: "Equipment failure",
  LBE: "Laboratory error",
  PST: "Processed after specified time",
  CLT: "Clotted",
  CTM: "Contaminated",
  HEM: "Hemolyzed",
  ICT: "Icteric",
  LIP: "Lipemic",
  LYS: "Lysed",
  SNP: "Sample Not Processed",
};

export const RECEIPT_STATUSES: statusOptions = {
  SAT: "Satisfactory",
  NBL: "No Blood",
  LBE: "Label Issue",
};

export const RECEIPT_STATUSES_CRYO: statusOptions = {
  SAT: "Satisfactory",
  LBE: "Label Issue",
};
