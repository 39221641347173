import _ from "lodash";
import validate from "validate.js";

const isValidPrimaryBarcode = (barcode) => {
  var constraints = {
    barcode: {
      presence: true,
      format: {
        pattern: /^([0-9]).*$/,
      },
      length: function (value) {
        if (value) {
          return { is: 7 };
        }
        return false;
      },
    },
  };

  return !validate({ barcode }, constraints);
};

const isValidPrimaryAliquot = (aliquotId, type, col) => {
  var constraints = {
    aliquotId: {
      presence: true,
      format: function (value) {
        if (value && type === "WHOLE" && col === 1) {
          return { pattern: /^([0-9]{7}(01))$/ };
        } else if (value && type === "WHOLE" && col === 2) {
          return { pattern: /^([0-9]{7}(02))$/ };
        }
        return false;
      },
      length: function (value) {
        if (value) {
          return { is: 9 };
        }
        return false;
      },
    },
  };

  return !validate({ aliquotId }, constraints);
};

const isValidPrimaryAliquotSimple = (aliquotId, type) => {
  let regex = /^([0-9]).*(01|02)$/;
  if (type === "SERUM") {
    regex = /^([0-9]).*(03|04)$/;
  }

  if (type === "PLASMA") {
    regex = /^([0-9]).*(05|06)$/;
  }

  var constraints = {
    aliquotId: {
      presence: true,
      format: { pattern: regex },
      length: function (value) {
        if (value) {
          return { is: 9 };
        }
        return false;
      },
    },
  };

  return !validate({ aliquotId }, constraints);
};

const isValidAliquotAnyType = (aliquotId, type) => {
  let regex = /^([0-9]).*(01|02|03|04|05|06)$/;

  var constraints = {
    aliquotId: {
      presence: true,
      format: { pattern: regex },
      length: function (value) {
        if (value) {
          return { is: 9 };
        }
        return false;
      },
    },
  };

  return !validate({ aliquotId }, constraints);
};

const aliquotUtils = {
  isValidPrimaryBarcode,
  checkPrimaryBarcodes: (barcodes) => {
    return barcodes.filter(isValidPrimaryBarcode);
  },
  isValidAliquotAnyType,
  isValidPrimaryAliquotSimple,
  checkvalidAliquots: (barcodes, aliquots, type) => {
    const totalBarcodes = barcodes.length || 0;
    const requiredAliquots = barcodes.length * 2;
    var aliq1 = [...aliquots];
    var aliq2 = _.remove(aliq1, function (n, index) {
      return index % 2 === 0;
    });

    return {
      totalBarcodes,
      requiredAliquots,
      aliq1,
      aliq2,
      validAliq1: aliq1.filter((aliq) => {
        return isValidPrimaryAliquot(aliq, type, 1);
      }),
      validAliq2: aliq2.filter((aliq) => {
        return isValidPrimaryAliquot(aliq, type, 2);
      }),
    };
  },
};

export default aliquotUtils;
