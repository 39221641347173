import validatejs from "validate.js";
validatejs.validators.custom = function (
  value: string,
  options: any,
  key: number,
  attributes: any
) {
  if (
    options.type === "WHOLE" &&
    options.col === 0 &&
    value !== `${options.barcode}01`
  ) {
    return "Invalid WHOLE BLOOD aliquot for position and type";
  }

  if (
    options.type === "WHOLE" &&
    options.col === 1 &&
    value !== `${options.barcode}02`
  ) {
    return "Invalid WHOLE BLOOD aliquot for position and type";
  }

  if (
    options.type === "SERUM" &&
    options.col === 0 &&
    value !== `${options.barcode}03`
  ) {
    return "Invalid SERUM aliquot for position and type";
  }

  if (
    options.type === "SERUM" &&
    options.col === 1 &&
    value !== `${options.barcode}04`
  ) {
    return "Invalid SERUM aliquot for position and type";
  }

  if (
    options.type === "PLASMA" &&
    options.col === 0 &&
    value !== `${options.barcode}05`
  ) {
    return "Invalid PLASMA aliquot for position and type";
  }

  if (
    options.type === "PLASMA" &&
    options.col === 1 &&
    value !== `${options.barcode}06`
  ) {
    return "Invalid PLASMA aliquot for position and type";
  }

  if (
    !(
      value === `${options.barcode}01` ||
      value === `${options.barcode}02` ||
      value === `${options.barcode}03` ||
      value === `${options.barcode}04` ||
      value === `${options.barcode}05` ||
      value === `${options.barcode}06`
    )
  ) {
    return "Invalid aliquot";
  }
};

export class Aliquot {
  isPrimary: boolean = false;
  value: string;
  row?: number = 0;
  column?: number;
  columns?: number;
  index?: number;
  type?: string | null;
  isValid = false;
  isException = false;
  messages?: any;
  length: number = 9;
  barcode: string = "";
  exceptions?: string[];

  constructor(
    barcode: string,
    aliquot: string,
    type?: string | null,
    index?: number,
    columns?: number,
    exceptions?: string[]
  ) {
    this.value = aliquot;
    this.column = index && columns ? index % columns : undefined;
    this.columns = columns;
    this.row = columns ? 10 % columns : undefined;
    this.index = index;
    this.type = type;
    this.barcode = barcode;
    this.exceptions = exceptions;
    this.validate(barcode, aliquot, this.type, this.index, exceptions);
  }

  validate = (
    barcode: string,
    aliquot: string,
    type: string | null | undefined,
    col: any,
    exceptions?: string[]
  ) => {
    let constraints: any = {
      aliquot: {
        presence: true,
        custom: { barcode, type, col },
        length: function (value: string) {
          if (value) {
            return {
              is: 9,
              message: `Not 9 digits`,
            };
          }
          return false;
        },
      },
    };

    if (aliquot) {
      if (exceptions && exceptions.includes(aliquot)) {
        this.isValid = true;
        this.isException = true;
      } else {
        this.messages = validatejs({ aliquot }, constraints, {
          fullMessages: false,
        });
        this.isException = false;
        this.isValid = this.messages?.aliquot ? false : true;
      }
    }

    return this.isValid;
  };
}
export default Aliquot;
