import React from "react";
import { Route, Switch } from "react-router-dom";
import Primary from "./scenes/Primary";
import Aliquots from "./scenes/Aliquots";
import Blood from "./scenes/Blood";
import { ROUTES } from "../../core/constants/routes.constants";
import Aliquots2 from "./scenes/Aliquots2";
import Aliquots3 from "./scenes/Aliquots3";
import AliquotsConf from "./scenes/AliquotsConfirm";
import CompleteScene from "./scenes/Complete";

function SamplingScene() {
  return (
    <Switch>
      <Route path={ROUTES.samplingAliquots} component={Aliquots} />
      <Route path={ROUTES.samplingAliquots2} component={Aliquots2} />
      <Route path={ROUTES.samplingAliquots3} component={Aliquots3} />
      <Route path={ROUTES.samplingAliquotsConfirm} component={AliquotsConf} />
      <Route path={ROUTES.samplingAliquotsFinalise} component={CompleteScene} />
      <Route path={ROUTES.samplingPrimary} component={Primary} />
      <Route path={ROUTES.sampling} component={Blood} />
    </Switch>
  );
}

export default SamplingScene;
