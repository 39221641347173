import React from "react";
import { FormStepper } from "@omnigenbiodata/react";
import MainLayout from "../../../../layouts/Main";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { useAppDispatch } from "../../../../store";
import { setSampleType } from "../../../../store/sampling";
import { Typography } from "@material-ui/core";

function BloodScene() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleClick = (type: string) => {
    dispatch(setSampleType(type));
    history.push(ROUTES.samplingPrimary);
  };

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={0}
      />
      <Typography paragraph component="h2" variant="h5" align="center">
        Sample Types
      </Typography>
      <Typography paragraph component="p" variant="body1" align="center">
        Please select which sample type you are aliquoting:
      </Typography>
      <Box mb={2}>
        <Button
          fullWidth
          color="default"
          size="large"
          variant="outlined"
          onClick={() => {
            handleClick("WHOLE");
          }}
        >
          Whole Blood
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          fullWidth
          color="default"
          size="large"
          variant="outlined"
          onClick={() => {
            handleClick("SERUM");
          }}
        >
          Serum
        </Button>
      </Box>{" "}
      <Box mb={2}>
        <Button
          fullWidth
          color="default"
          size="large"
          variant="outlined"
          onClick={() => {
            handleClick("PLASMA");
          }}
        >
          Plasma
        </Button>
      </Box>
    </MainLayout>
  );
}

export default BloodScene;
