import { createSlice } from "@reduxjs/toolkit";
import { ReceiptState } from "./types";
import { StoreStatus } from "../../core/types/common.types";

// Initial State
const initialState: ReceiptState = {
  errors: null,
  barcodes: null,
  status: StoreStatus.IDLE,
  values: null,
};
// Slice
export const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    addBarcode: (state, action) => ({
      ...state,
      barcodes: state.barcodes
        ? [...state.barcodes, action.payload]
        : [action.payload],
    }),
    addBarcodes: (state, action) => ({
      ...state,
      barcodes: state.barcodes
        ? [...state.barcodes, ...action.payload]
        : [...action.payload],
    }),
    removeBarcode: (state, action) => ({
      ...state,
      barcodes: state.barcodes
        ? [...state.barcodes.filter((bcode) => action.payload !== bcode)]
        : [],
    }),
    receiptForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
    }),
  },
  extraReducers: {},
});

// Action creators
export const { addBarcode, addBarcodes, receiptForward, removeBarcode } =
  receiptSlice.actions;

export default receiptSlice.reducer;
