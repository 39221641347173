import React, { useMemo } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { Box, Paper, Typography } from "@material-ui/core";
import {
  ButtonRow,
  FormInput,
  FormSelect,
  FormStepper,
  SelectOption,
} from "@omnigenbiodata/react";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/index";
import {
  aliquots2Selector,
  barcodesSelector,
  sampleTypeSelector,
} from "../../../../store/sampling/selectors";
import { sampleForward } from "../../../../store/sampling";
import { SampleMatrix } from "../../../../core/utils/Samples";
import {
  PRIMARY_STATUSES,
  statusOptions,
} from "../../../../core/constants/values.constants";

function AliquotsConfirm() {
  const history = useHistory();
  const barcodes = useAppSelector(barcodesSelector);
  const aliquots = useAppSelector(aliquots2Selector);
  const sampleType = useAppSelector(sampleTypeSelector);
  const dispatch = useAppDispatch();

  const samples = useMemo(
    () => new SampleMatrix(sampleType, barcodes, aliquots),
    [sampleType, barcodes, aliquots]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aliquotCol1: samples.aliquotRows.map((row) => row.aliquots[0]),
      aliquotVolumeCol1: _.times(barcodes.length, () =>
        sampleType === "WHOLE" ? "1.8" : "1"
      ),
      aliquotConditionCol1: _.times(barcodes.length, () => "SAT"),
      aliquotCol2: samples.aliquotRows.map((row) => row.aliquots[1]),
      aliquotVolumeCol2: _.times(barcodes.length, () =>
        sampleType === "WHOLE" ? "1.8" : "1"
      ),
      aliquotConditionCol2: _.times(barcodes.length, () => "SAT"),
    },
    onSubmit: (values: any) => {
      dispatch(sampleForward(values));
      history.push(ROUTES.samplingAliquotsFinalise);
    },
  });

  if (!sampleType) {
    return <Redirect to={ROUTES.sampling} />;
  }

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Sample Processing
      </Typography>
      <FormStepper
        steps={["Sample", "Primary", "Aliquots", "Confirm"]}
        activeStep={3}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Paper elevation={3} variant="elevation">
            <Box pt={10} pb={10} pl={10} pr={10}>
              <Typography paragraph component="h2" variant="h5" align="center">
                Aliquot Status
              </Typography>
              <Typography
                paragraph
                component="p"
                variant="body1"
                align="center"
              >
                Now add the status of each aliquot
              </Typography>

              {samples.aliquotRows.length > 0 && barcodes.length > 0 && (
                <table width={"100%"} style={{ margin: "0 auto" }}>
                  <thead>
                    <tr>
                      <th align="center">Pos</th>
                      <th align="left">Aliquot 1</th>
                      <th align="left">Vol 1</th>
                      <th align="left">Status 1</th>
                      <th align="left">Aliquot 2</th>
                      <th align="left">Vol 2</th>
                      <th align="left">Status 2</th>
                    </tr>
                  </thead>

                  <tbody>
                    {barcodes.map((bcode, index) => (
                      <tr
                        key={bcode}
                        style={{
                          background: samples.aliquotRows[index]
                            ? samples.aliquotRows[index]?.isValid
                              ? "white"
                              : samples.aliquotRows[index]?.aliquots.length < 2
                              ? "white"
                              : "#EDCCCC"
                            : "white",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>
                          <FormInput
                            label="ID 1"
                            name={`aliquotCol1[${index}]`}
                            error={
                              samples.aliquotRows[index]?.aliquots[0]?.isValid
                                ? undefined
                                : ""
                            }
                            touched={
                              samples.aliquotRows[index]?.aliquots[0]?.value
                                ? true
                                : false
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[0]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                        <td style={{ width: 60 }}>
                          <FormInput
                            label="Vol 1"
                            name={`aliquotVolumeCol1[${index}]`}
                            error={
                              formik.errors?.aliquotVolumeCol1
                                ? formik.errors?.aliquotVolumeCol1[index]
                                : undefined
                            }
                            touched={
                              formik.touched?.aliquotVolumeCol1
                                ? formik.touched?.aliquotVolumeCol1
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.aliquotVolumeCol1[index]}
                          />
                        </td>
                        <td style={{ minWidth: 120 }}>
                          <FormSelect
                            label="Condition 1"
                            name="aliquotConditionCol1[]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Condition"
                            options={
                              Object.keys(PRIMARY_STATUSES).reduce(
                                (
                                  previousValue: any[],
                                  key: keyof statusOptions
                                ) => {
                                  return [
                                    ...previousValue,
                                    {
                                      value: key,
                                      label: key,
                                    },
                                  ];
                                },
                                []
                              ) as SelectOption[]
                            }
                            value={formik.values.aliquotConditionCol1[index]}
                          />
                        </td>
                        <td>
                          <FormInput
                            label="ID 2"
                            name={`aliquotCol2[${index}]`}
                            error={
                              samples.aliquotRows[index]?.aliquots[1]?.isValid
                                ? undefined
                                : ""
                            }
                            touched={
                              samples.aliquotRows[index]?.aliquots[1]?.value
                                ? true
                                : false
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              samples.aliquotRows[index]?.aliquots[1]?.value ||
                              ""
                            }
                            disabled
                          />
                        </td>
                        <td style={{ width: 60 }}>
                          <FormInput
                            label="Vol 2"
                            name={`aliquotVolumeCol2[${index}]`}
                            error={
                              formik.errors?.aliquotVolumeCol2
                                ? formik.errors?.aliquotVolumeCol2[index]
                                : undefined
                            }
                            touched={
                              formik.touched?.aliquotVolumeCol2
                                ? formik.touched?.aliquotVolumeCol2
                                : undefined
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.aliquotVolumeCol2[index]}
                          />
                        </td>
                        <td style={{ minWidth: 120 }}>
                          <FormSelect
                            label="Condition 2"
                            name="aliquotConditionCol2[]"
                            onChange={() => undefined}
                            onBlur={() => undefined}
                            placeholder="Condition"
                            options={
                              Object.keys(PRIMARY_STATUSES).reduce(
                                (
                                  previousValue: any[],
                                  key: keyof statusOptions
                                ) => {
                                  return [
                                    ...previousValue,
                                    {
                                      value: key,
                                      label: key,
                                    },
                                  ];
                                },
                                []
                              ) as SelectOption[]
                            }
                            value={formik.values.aliquotConditionCol2[index]}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Box>
          </Paper>
        </Box>
        <ButtonRow showForward={samples.aliquotRows.length > 0} />
      </form>
    </MainLayout>
  );
}

export default AliquotsConfirm;
