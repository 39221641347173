import validatejs from "validate.js";

export class Sample {
  value: string;
  isValid = false;
  messages = [];
  constructor(barcode: string) {
    this.value = barcode;
    this.validate(barcode);
  }

  validate = (barcode: string) => {
    var constraints = {
      barcode: {
        presence: true,
        format: {
          pattern: /^([0-9]{7}).*$/,
        },
        length: function (value: string) {
          if (value) {
            return { is: 7 };
          }
          return false;
        },
      },
    };

    if (barcode) {
      this.messages = validatejs({ barcode }, constraints);
      this.isValid = !this.messages;
    }

    return this.isValid;
  };
}

export default Sample;
