import _ from "lodash";
import SampleBatch from "./samplebatch.class";

export class ReceiptMatrix {
  barcodes: string[] = [];
  batches: SampleBatch[] = [];

  constructor(barcodes: string[]) {
    this.barcodes = barcodes;
    this.batches = _.chunk(this.barcodes, 9).map(
      (batch) => new SampleBatch(batch)
    );
  }
}
