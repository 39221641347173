import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.receipt.errors && state.receipt.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.receipt.status === StoreStatus.BUSY;

export const barcodesSelector = (state: RootState): string[] =>
  state.receipt.barcodes || [];

export const valuesSelector = (state: RootState): any =>
  state.receipt.values || {};
