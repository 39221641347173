import React, { ReactNode } from "react";
import { PageWrapper, DiscoverMeLogo } from "@omnigenbiodata/react";
import Box from "@material-ui/core/Box";
import { ROUTES } from "../../core/constants/routes.constants";
import { Link } from "react-router-dom";

interface MainLayoutProps {
  children: ReactNode;
}
function MainLayout({ children }: MainLayoutProps) {
  return (
    <PageWrapper>
      <div>
        <Box mb={2}>
          <Link to={ROUTES.root}>
            <DiscoverMeLogo sub="sa" size="small" />
          </Link>
        </Box>
        {children}
      </div>
    </PageWrapper>
  );
}

export default MainLayout;
