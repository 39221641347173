import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import { Box, Button, Typography } from "@material-ui/core";
import { FormInput, ButtonRow, FormStepper } from "@omnigenbiodata/react";
// import { RiBarcodeLine } from "react-icons/ri";
import MainLayout from "../../../../layouts/Main";
import { ROUTES } from "../../../../core/constants/routes.constants";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/index";
import { barcodesSelector } from "../../../../store/receipt/selectors";
import { addBarcodes, receiptForward } from "../../../../store/receipt";
// import aliquotUtils from "../../../../core/utils/aliquots.util";
import { ReceiptMatrix } from "../../../../core/utils/ReceiptMatrix";
import SampleBatch from "../../../../core/utils/samplebatch.class";
import { RiBarcodeLine } from "react-icons/ri";

function TotalsScene() {
  const history = useHistory();
  const barcodes: string[] = useAppSelector(barcodesSelector);
  const [tempBarcodes, setTempBarcode] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const tempBatch = new SampleBatch(tempBarcodes);
  const receiptMatrix = new ReceiptMatrix(barcodes);
  const batches = receiptMatrix.batches;

  useEffect(() => {
    const scanFunc: any = (event: any) => {
      const code: string = event.detail.scanCode;

      const newBatch = new SampleBatch([...tempBarcodes, code]);

      const isValidBatch = newBatch ? newBatch.isValid : false;

      if (code && isValidBatch && tempBarcodes.length < 9) {
        setTempBarcode([...tempBarcodes, code]);
      }
      return null;
    };
    document.addEventListener("scan", scanFunc, false);
    return () => {
      document.removeEventListener("scan", scanFunc);
    };
  }, [tempBarcodes, setTempBarcode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      barcodes: tempBatch
        ? _.assign(
            _.fill(new Array(9), ""),
            tempBatch.barcodes.map((barcode: any) => barcode.value)
          )
        : [],
    },
    onSubmit: (values: any) => {
      dispatch(receiptForward(values));
      history.push(ROUTES.receiptConfirm);
    },
  });

  return (
    <MainLayout>
      <Typography paragraph component="h1" variant="h3" align="center">
        Receipt of Samples
      </Typography>
      <FormStepper steps={["Receipt", "Confirm", "Complete"]} activeStep={0} />

      <input type="text" style={{ width: 1, opacity: 0 }} autoFocus />
      {tempBatch.barcodes.length === 0 && (
        <>
          {batches.length === 0 && (
            <>
              <div style={{ fontSize: 100, textAlign: "center" }}>
                <RiBarcodeLine color="#cccccc" />
              </div>
              <Typography align="center">
                Scan your first primary sample tube barcode
              </Typography>
            </>
          )}
          {batches.length > 0 && (
            <Typography align="center">
              Scan another primary sample tube barcode
            </Typography>
          )}
        </>
      )}

      {tempBatch.barcodes.length > 0 && (
        <Box mb={5}>
          <Typography paragraph component="h3" variant="h4" align="center">
            New Sample Kit:
          </Typography>
          {tempBatch.barcodes && (
            <Typography paragraph component="p" variant="body1" align="center">
              Please scan a primary barcode from a sample bag
            </Typography>
          )}
          <table width={"100%"} cellPadding={2} style={{ margin: "0 auto" }}>
            <thead>
              <tr>
                <th
                  align="center"
                  colSpan={3}
                  style={{
                    borderTop: "1px solid grey",
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Primary Tubes
                </th>
                <th
                  align="center"
                  colSpan={6}
                  style={{
                    borderTop: "1px solid grey",
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Cryovials
                </th>
                <th align="left"></th>
              </tr>
              <tr>
                <th
                  align="center"
                  style={{
                    borderLeft: "1px solid grey",
                  }}
                >
                  DNA
                </th>
                <th align="center">SER</th>
                <th
                  align="center"
                  style={{
                    borderRight: "1px solid grey",
                  }}
                >
                  PLA
                </th>
                <th
                  align="center"
                  style={{
                    borderLeft: "1px solid grey",
                  }}
                >
                  CRYO 01
                </th>
                <th align="center">CRYO 02</th>
                <th align="center">CRYO 03</th>
                <th align="center">CRYO 04</th>
                <th align="center">CRYO 05</th>
                <th
                  align="center"
                  style={{
                    borderRight: "1px solid grey",
                  }}
                >
                  CRYO 06
                </th>
                <th align="center"></th>
              </tr>
            </thead>

            <tbody>
              <tr key={formik.values.barcodes[0]}>
                <td
                  style={{
                    borderLeft: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Primary 1"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[0]}
                  />
                </td>
                <td
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Primary 2"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[1]}
                  />
                </td>
                <td
                  style={{
                    borderRight: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Primary 3"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[2]}
                  />
                </td>
                <td
                  style={{
                    borderLeft: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 1"
                    name={`barcodes[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[3]}
                  />
                </td>
                <td
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 2"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[4]}
                  />
                </td>
                <td
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 3"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[5]}
                  />
                </td>
                <td
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 4"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[6]}
                  />
                </td>
                <td
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 5"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[7]}
                  />
                </td>
                <td
                  style={{
                    borderRight: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <FormInput
                    label="Cryo 6"
                    name={`barcode[]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcodes[8]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {tempBatch.isValid && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                dispatch(addBarcodes(tempBarcodes));
                setTempBarcode([]);
              }}
            >
              Confirm
            </Button>
          )}
        </Box>
      )}

      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Box pt={10} pb={10}>
            {batches.length > 0 && (
              <Box mb={5}>
                <Typography
                  paragraph
                  component="h2"
                  variant="h4"
                  align="center"
                >
                  Confirmed sample kits
                </Typography>
                <table
                  width={"100%"}
                  cellPadding={2}
                  style={{ margin: "0 auto" }}
                >
                  <thead>
                    <tr>
                      <th
                        align="center"
                        colSpan={3}
                        style={{
                          borderTop: "1px solid grey",
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        Primary Tubes
                      </th>
                      <th
                        align="center"
                        colSpan={6}
                        style={{
                          borderTop: "1px solid grey",
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        Cryovials
                      </th>
                      <th align="left"></th>
                    </tr>
                    <tr>
                      <th
                        align="center"
                        style={{
                          borderLeft: "1px solid grey",
                        }}
                      >
                        DNA
                      </th>
                      <th align="center">SER</th>
                      <th
                        align="center"
                        style={{
                          borderRight: "1px solid grey",
                        }}
                      >
                        PLA
                      </th>
                      <th
                        align="center"
                        style={{
                          borderLeft: "1px solid grey",
                        }}
                      >
                        CRYO 01
                      </th>
                      <th align="center">CRYO 02</th>
                      <th align="center">CRYO 03</th>
                      <th align="center">CRYO 04</th>
                      <th align="center">CRYO 05</th>
                      <th
                        align="center"
                        style={{
                          borderRight: "1px solid grey",
                        }}
                      >
                        CRYO 06
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {batches.map((batch: SampleBatch, index: number) => {
                      const barcode1 = batch.barcodes[0] || "";
                      const barcode2 = batch.barcodes[1] || "";
                      const barcode3 = batch.barcodes[2] || "";
                      const cryo1 = batch.barcodes[3] || "";
                      const cryo2 = batch.barcodes[4] || "";
                      const cryo3 = batch.barcodes[5] || "";
                      const cryo4 = batch.barcodes[6] || "";
                      const cryo5 = batch.barcodes[7] || "";
                      const cryo6 = batch.barcodes[8] || "";

                      return (
                        <tr key={`${barcode1?.value}-${index}-committed`}>
                          <td
                            style={{
                              borderLeft: "1px solid grey",
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {barcode1.value}
                          </td>
                          <td
                            style={{
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {barcode2.value}
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid grey",
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {barcode3.value}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid grey",
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo1.value}
                          </td>
                          <td
                            style={{
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo2.value}
                          </td>
                          <td
                            style={{
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo3.value}
                          </td>
                          <td
                            style={{
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo4.value}
                          </td>
                          <td
                            style={{
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo5.value}
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid grey",
                              borderBottom:
                                index === batches.length - 1
                                  ? "1px solid grey"
                                  : undefined,
                            }}
                          >
                            {cryo6.value}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Box mt={2}>
                  <ButtonRow
                    showBack={false}
                    buttonSize="small"
                    forwardLabel={`Submit ${batches.length} sample bag${
                      batches.length > 1 ? "s" : ""
                    }`}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </MainLayout>
  );
}

export default TotalsScene;
